nav {
    position: fixed;
    top: 6px;
    right: 15px;
    /* width: 100px; */
    z-index: 100;
    font-size: 95%;
    /* background-color: rgba(0, 0, 0, 0.5); */
    /* padding: 15px; */
}

@media (max-width: 700px) {
    nav {
        left: 0;
        right: 0;
        font-size: 90%;
    }
}

nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    /* overflow: hidden; */
    text-align: center;
}

nav li {
    display: inline;
    /* padding: 0 2%; */
}

nav a {
    display: inline-block;
    text-align: center;
    /* padding: 50px 30px 0px 5px; */
    text-decoration: none;
    color: white;
    /* border-bottom: 2px solid white; */
    /* border-left: 2px solid white; */
    padding: 5px 15px 0px;
    /* border: 2px solid white; */
    text-transform: lowercase;

    transform: translateY(0px);
    /* opacity: 0.7; */
}

nav a, nav a::after {
    transition: transform 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
    /* transform-origin:  0% 50%; */
}

nav a:hover {
    /* transform: translateY(5px); */
    /* padding: 20px 30px; */
    /* border-bottom: 2px solid white; */
    opacity: 1;
}

nav a::after {
    display: block;
    /* margin: 0 -10px; */
    content: '';
    border-bottom: solid 1px rgba(255,255,255,1);
    transform: scaleX(0);
    transform-origin: left;
    /* transition-delay: 0.3s; */
}

nav a:hover::after, nav a.active::after {
    transform: scaleX(1);
}
/* 
@media (max-width: 576px) {
    nav {
        font-size: 20px;
    }
} */

.app nav {
    transform: translate3d(0, -300px, 0);
}

.app-enter-active nav, .app-enter-done nav {
    transform: translate3d(0, 0, 0);
    transition: transform 2s cubic-bezier(0.075, 0.82, 0.165, 1);
    transition-delay: 2s;
}
