.music {
    /* background: #01251F; */
    padding: 15px 10% 50px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;

    .background {
        background-image: url("./IMG_7695.jpg");
    }

    .presentation {
        max-width: 670px;
    }
    
    .visualizer {
        // position: fixed;
        z-index: -10;
        top: 0;
        left: 0;
        // opacity: 0.3;
        mix-blend-mode: overlay;
    }
}

ol.tracks {
    margin: 0;
    padding: 0;
    width: 100%;
    // list-style-type: decimal-leading-zero;
    list-style-type: none;
    // border-left: 2px solid #8bb9df;
    // border-top: 1px solid white;

    li {
        position: relative;
        margin: 5px 0;
        padding: 0;
        // overflow-x: hidden;
        transform: translateX(0);
        height: 80px;
        font-size: 110%;
        line-height: 1.2em;
        max-width: 700px;

        .number {
            position: absolute;
            width: 25px;
            text-align: right;
            font-size: 80%;
            left: -35px;
            bottom: 16px;
            color: #999999;
        }

        @media (max-width: 700px) {
            .number {
                display: none;
            }
        }

        .play-pause {
            position: absolute;
            left: 5px;
            bottom: 18px;
            background: none;
            color: white;
            // transform: translateX(-30px);
            opacity: 0;
            transition: opacity 200ms linear,
                        transform 800ms cubic-bezier(0.075, 0.82, 0.165, 1);

            svg {
                width: 30px;
                height: 30px;
            }

            &:hover {
                opacity: 1 !important;
            }
        }

        .progress {
            // visibility: hidden;
            position: absolute;
            bottom: 0px;
            height: 1px;
            width: 0%;
            opacity: 0;
            background-color: white;
            // border-radius: 5px;
            transition: width 250ms linear, opacity 2s linear;
        }

        .progress-bg {
            position: absolute;
            bottom: 0px;
            height: 1px;
            width: 100%;
            opacity: 1;
            background-color: #888888;
            transform: scaleX(0);
            transform-origin: left;
            transition: transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
        }

        .click-area {
            display: block;
            height: 6em;
            // padding-top: 40px;
            // padding-bottom: 15px;
            // padding-left: 15px;
            overflow-x: hidden;
            text-decoration: none;
            color: white;
            transition: height 800ms ease-in-out,
                        transform 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

            &::before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                /* background-color: rgba(39, 56, 66, 0.6); */
                background-color: rgba(255, 255, 255, 0.3);
                transform: scaleX(0);
                transform-origin: left;
                transition: transform 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
            }

            &:hover &::before {
                transform: scaleX(1);
            }
        }

        .info {
            /* margin-bottom: 2em; */
            margin-left: 10px;
            opacity: 0.8;
            /* transform: scale(0.5); */
            transform-origin: center left;
            transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1);
            position: absolute;
            bottom: 18px;
            left: 0px;

            .artist {
                color: #aaaaaa;
                font-size: 80%;
            }
        
            .title {
                font-size: 100%;
            }
        }

        &:hover, &.active {
            .play-pause {
                transform: translateX(0);
                opacity: 0.5;
            }

            .info {
                // color: black;
                transform: translateX(40px);
            }
        }

        &:hover {
            .progress-bg {
                // opacity: 0.4;
                transform: scaleX(1);
            }
        }

        &.playing {
            .progress {
                opacity: 0.3;
            }
        }

        &.active {
            .click-area {
                // height: 6em;
                text-shadow: 0 0 8px rgba(255, 255, 255, 0.5);
                // font-size: 150%;
                // line-height: 1.4em;
                // border-bottom: 2px solid rgba(200, 200, 200, 0.5);
                // border-bottom: 2px solid #aaaaaa;

                .info {
                    opacity: 1;
                    // transform: scale(1) translateX(50px) !important;
                    /* margin-top: 30px; */
                    /* margin-bottom: 30px; */

                }
            }

            .progress {
                box-shadow: 0 0 7px 1px rgba(255, 255, 255, 0.5);
                opacity: 1;
                height: 1px;
            }

            .progress-bg {
                // opacity: 0.7;
            }
        }
    }
}

.page-enter-active {
    .visualizer {
        display: none;
    }
}

.page-exit-active {
    .visualizer {
        display: none;
    }
}
