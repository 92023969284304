
.photos {
    // background-color: #070707;
    padding: 1%;

    .background {
        display: block;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .presentation {
        max-width: 600px;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            display: block;
            // height: 100vh;
            margin-top: 80vh;
            text-align: left;
        }

        // li:nth-child(odd) {
        //     text-align: left;
        // }

        // li:nth-child(even) {
        //     text-align: right;
        // }
    }

    .photo {
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate3d(-50%, -50%, 0);
        display: block;
        visibility: hidden;
        max-width: 100%;
        max-height: 90vh;
        width: auto;
        height: auto;
        box-shadow: 0px 3px 15px 3px rgba(0, 0, 0, 0.75);
        // transform: rotate3d(0, 1, 0, 20deg);
        // border: 30px solid #cccccc;
        // transform-origin: left;
    }

    video:focus {
        outline: none;
    }
}

// .page-enter .photos {
//     .background {
//         transform: translate3d(100vw, 0, 0);
//         opacity: 1;
//     }
// }

// .page-enter-active .photos {
//     .background {
//         transform: translate3d(0, 0, 0);
//         transition: transform ease-out 2s;
//         transition-delay: 3s;
//     }
// }
