.home {
    /* min-height: 100vh; */
    position: fixed;
    /* height: 100vh; */
    /* min-height: 500px; */
    /* width: 100%; */
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;

    .background {
        background-image: url("./IMG_0920.jpg");
    }

    .rain {
        mix-blend-mode: overlay;
        // opacity: 0.8;
    }

    .title {
        position: relative;
        width: 100%;
        height: 52.5vh;
    }

    h1 {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 20px;
        margin: 0;
        /* font-family: 'Dosis', 'Arial Narrow', sans-serif; */
        font-size: 4.5em;
        /* -webkit-text-stroke: 2px white; */
        /* -webkit-text-fill-color: rgba(255, 255, 255, 0.3); */
        /* text-shadow: 0 0px 8px rgba(255,255,255,0.7); */
        text-align: center;
        text-transform: uppercase;
        /* letter-spacing: -2px; */
        line-height: 0.9em;
        font-weight: bold;
        opacity: 1;
    }

    .christian, .sandberg {
        position: absolute;
        width: 100%;
        bottom: 0;
        /* transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1); */
        /* transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1); */
    }

    .christian {
        bottom: 0.75em;
        /* right: 50%; */
        /* transform: translateX(50%); */
    }

    .presentation {
        position: relative;
        // text-shadow: 0 2px 2px black;
        width: 45%;
        max-width: 800px;
        margin: 50px;

        /* transition: transform 1s cubic-bezier(0.075, 0.82, 0.165, 1); */
        /* transition-delay: 1s; */
    }

    @media (max-width: 700px) {
        h1 {
            font-size: 50px;
        }

        .presentation {
            width: auto;
        }
    }
}

.links {
    position: fixed;
    bottom: 50px;
    right: 40px;
    margin: 0;
    margin-left: 15px;
    padding-left: 15px;
    list-style-type: none;
    padding: 0;
    font-size: 40px;
    /* background-color: rgba(0, 0, 0, 0.5); */
}

.links li {
    display: inline;
    margin: 0;
}

.links a {
    display: inline-block;
    color: white;
    padding: 10px;
    /* border: 2px solid rgba(255,255,255,0.5); */
    /* background-color: rgba(0, 0, 0, 0.5); */
    /* border-radius: 200px; */
    transition: opacity 0.2s ease-out;
    opacity: 0.6;
}

.links a:hover {
    opacity: 1;
}

.links svg {
    width: 35px;
    height: 35px;
}

@media (max-width: 700px) {
    .links {
        position: relative;
        text-align: center;
        right: 0;
    }
}

// .page-enter .christian {
//     transform: translate3d(0, -200px, 0);
// }

// .page-enter .sandberg {
//     transform: translate3d(0, -100px, 0);
// }

// .page-enter-active .christian, .page-enter-active .sandberg {
//     transform: translate3d(0, 0, 0);
//     /* transition-delay: 1s; */
//     transition: transform 2s ease-in-out;
// }
/* 
.page-exit-active .christian {
    transform: translateY(-100vh);
    transition-timing-function: ease-in;
}
*/

/* 
.page-exit-active .sandberg {
    transform: translateY(100vh);
    transition-timing-function: ease-in;
}
*/

// .page-exit-active .christian {
//     transform: translate3d(0, 100px, 0);
//     transition: transform 2s ease-in;
// }

// .page-exit-active .sandberg {
//     transform: translate3d(0, 150px, 0);
//     transition: transform 2s ease-in;
// }

// .page-enter .presentation {
//     transform: translate3d(0, -100px, 0);
// }

// .page-enter-active .presentation {
//     transform: translate3d(0, 0, 0);
//     transition: transform 1.7s ease-in-out;
//     /* transition-delay: 1s; */
// }

/* 
#home:hover .sandberg {
    transform: translateX(50vw);
    opacity: 0;
}

#home:hover .background {
    opacity: 0;
    transform: scale(10);
}

#home:hover .presentation {
    transform: translateX(-100%);
}
 */
