@import url('https://fonts.googleapis.com/css?family=Sarpanch:400,900&display=swap');

body {
    font-family: 'Sarpanch', monospace;
    font-size: 18px;
    color: white;
    background-color: #0a0a0a; /*hsl(45, 30%, 0%);*/
    /* background: radial-gradient(circle, hsl(45, 0%, 15%) 0%, hsl(0, 0%, 5%) 80%) no-repeat; */
    /* background-attachment: fixed; */
    min-height: 100vh;
}

@supports (-webkit-overflow-scrolling: touch) {
    body {
        background-attachment: scroll;
    }
}

html, body {
    overflow-x: hidden;
    max-width: 100%;
}

button {
    padding: 0;
    text-align: left;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    /* show a hand cursor on hover; some argue that we
    should keep the default arrow cursor for buttons */
    cursor: pointer;
}

section {
    position: absolute;
    overflow-x: hidden;
    top: 0;
    left: 25px;
    right: 25px;
}

.background {
    position: fixed;
    z-index: -50;
    top: 40px;
    left: 25px;
    right: 25px;
    bottom: 40px;
    box-shadow: 0px 3px 15px 3px rgba(0, 0, 0, 0.75);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // background-attachment: fixed;
}

.scroll-container {
    width: 100%;
}

.presentation {
    padding: 15px;
    margin: 100px 0 50px;
    text-align: left;
    line-height: 150%;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.4);
}

.app:not(.app-enter-done) {
    section {
        opacity: 0;
        z-index: 50;
        transform: translate3d(0, -100vh, 0);
    }

    .background {
        opacity: 0;
        transform: translate3d(0, 20vh, 0);
    }
}

.app-enter-active:not(.app-enter-done) {
    section {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: transform 2s cubic-bezier(0.075, 0.82, 0.165, 1), opacity 1s ease-in;
        transition-delay: 0.75s;
    }

    .background {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: transform 2s cubic-bezier(0.075, 0.82, 0.165, 1), opacity 1.5s ease-in;
        transition-delay: 0.75s;
    }
}

.page-exit-active {
    opacity: 0;
    transform: translate3d(0, 100vh, 0);
    transition: transform 1.5s cubic-bezier(0.835, 0, 0.925, 0.18), opacity 1s linear;
    // transition-delay: 0.5s;

    .background {
        opacity: 0;
        transform: translate3d(0, -20vh, 0);
        transition: transform 1.5s cubic-bezier(0.835, 0, 0.925, 0.18), opacity 2s linear;
    }
}

.page-enter {
    opacity: 0;
    z-index: 50;
    transform: translate3d(0, -100vh, 0);

    .background {
        opacity: 0;
        transform: translate3d(0, 20vh, 0);
    }
}

.page-enter-active {
    opacity: 1;
    transform: translate3d(0, 0, 0);
    transition: transform 1.5s cubic-bezier(0.075, 0.82, 0.165, 1), opacity 1s ease-in;
    transition-delay: 0.75s;

    .background {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transition: transform 1.5s cubic-bezier(0.075, 0.82, 0.165, 1), opacity 1.5s ease-in;
        transition-delay: 0.75s;
    }
}
